<template>
  <header class="bg-primary text-white p-4 shadow-md sticky top-0 z-50 dark:bg-gray-800">
    <div class="container mx-auto flex justify-between items-center">
      <h1 class="text-xl font-semibold">
        <router-link to="/" class="text-white mr-4 hover:underline">Edufy CBT Center</router-link>
      </h1>
      <div class="flex items-center">
        <nav v-if="logger && !logging" @click="logOut">
          <router-link to="/confirm">
            <i v-if="!path" style="cursor: pointer; font-style: normal" class="font-semibold mr-2">Confirm</i>
          </router-link>
          <i style="cursor: pointer; font-style: normal" class="font-semibold ml-2">Logout</i>
        </nav>
        <nav v-else-if="!logging && !logger && !pathLogin">
          <router-link to="/login">
            <i style="cursor: pointer; font-style: normal" class="font-semibold">Login</i>
          </router-link>
        </nav>
        <nav v-if="logging">
          <i style="cursor: pointer; font-style: normal" class="font-semibold">Logging Out <font-awesome-icon icon="spinner" spin class="text-2xl"/></i>
        </nav>
        <button @click="$emit('toggleDarkMode')" class="ml-4 px-4 py-2 bg-gray-700 text-white rounded dark:bg-gray-300 dark:text-gray-900 flex items-center">
    <!-- Toggle icon based on dark mode status -->
    <font-awesome-icon :icon="isDarkMode ? 'sun' : 'moon'" class="mr-2" />
    {{ isDarkMode ? 'Light Mode' : 'Dark Mode' }}
  </button>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "@/axios";
const isLoggedIn = localStorage.getItem('userLoggedIn') === 'true';
const isAdmin = localStorage.getItem('userIsAdmin') === 'true';


export default {
  name: 'HeaderComponent',
  data(){
    return {
      logging: false,
      loggedInStatus: isLoggedIn && isAdmin,
    }
  },
  computed:{
    logger(){
      return this.loggedInStatus;
    },
    isDarkMode(){
      return this.$store.state.isDarkMode
    },
    path(){
      return this.$route.path === '/confirm'
    },
    pathLogin(){
      return this.$route.path === '/login'
    }
  },
  mounted(){
    setTimeout(() => {
      if(localStorage.getItem("token")){
        this.getDetails()
      }
    }, 3000);
  },
  methods: {
    async logOut(){
      const authe = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const data ={
        "refresh": localStorage.getItem("refresh")
      }
      try {
        this.logging = true;
        const response = await axios.post("auth/logout/", data, authe)
        if (response) {
          localStorage.clear();
          this.loggedInStatus = false;
          this.logging = false;
          this.$router.push({name: 'Login'});
        }
      }
      catch (e) {
        console.log(e)
        this.logging = false;
          localStorage.clear();
          this.loggedInStatus = false;
          this.logging = false;
          this.$router.push({name: 'Login'});
      }
    },
    async getDetails() {
      const authe = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      try {
        const response = await axios("/examination/sub_status/", authe);
        if (response) {
          if(!response.data.status){
            this.logOut()
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped>
</style>
