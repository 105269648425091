<template>
<div :class="isDarkMode ? 'dark-bg text-white' : 'bg-gray-100 text-gray-900'">
  <div class="flex items-center justify-center min-h-screen" :class="{
    'bg-gray-900 text-white': isDarkMode,
    'bg-white text-gray-900': !isDarkMode
  }">
    <div v-if="!loading" :class="['p-8 rounded-lg shadow-lg w-full max-w-7xl mx-6 mt-4', isDarkMode ? 'bg-gray-800' : 'bg-white']">
      <div :class="isDarkMode ? 'bg-gray-700 text-white p-4 rounded-lg mb-4' : 'bg-gray-200 text-gray-900 p-4 rounded-lg mb-4'">
        <div class="flex items-center">
          <div class="flex-1 text-center md:text-left">
            <h2 class="text-xl font-bold">Name: {{ details.name }}</h2>
            <p class="text-xl font-bold">Class: {{ details.class }}</p>
          </div>
          <div class="ml-auto text-center">
            <img :src="details.image" alt="Student Image" class="thumbnail rounded"/>
          </div>
        </div>
      </div>
      <Questions :studentSlug="slug" />
    </div>
    <div v-else :class="isDarkMode ? 'text-gray-300' : 'text-gray-600'">Loading...</div>
  </div>
</div>

</template>

<script>
import Questions from '../components/QuestionsPage.vue';
const axios = require("@/axios");
import { mapGetters } from 'vuex';
const authe = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};
export default {
  name: 'ExaminationPage',
  components: {
    Questions
  },
  data() {
    return {
      details: {},
      loading: false
    };
  },
  beforeCreate() {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded');
    } else {
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
  },
  mounted() {
    this.getDetails();
  },
  props: ["slug"],
  methods: {
    async getDetails() {
      try {
        this.loading = true;
        const response = await axios(`/examination/student_details/${this.slug}/`, authe);
        if (response) {
          this.details = response.data;
        }
      } catch (e) {
        console.log(e);
        // Display error message to the user
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters(['isDarkMode']),
    isDark() {
      return this.$store.state.isDarkMode;
    }
  }
};
</script>

<style scoped>
.thumbnail {
  width: 75px;
  height: 75px;
}
@media (min-width: 768px) {
  .thumbnail {
    width: 150px;
    height: 150px;
  }
}
.dark-bg {
  background-color: #1a202c;
}
</style>
