<template>
  <div
    class="flex items-center justify-center flex-wrap space-x-1 space-y-2 sm:space-y-0"
    :class="isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'"
  >
    <!-- Previous Button -->
    <button
      type="button"
      @click="previousPage"
      :disabled="currentPage === 1 || disabled"
      class="btn px-2 py-1 rounded border border-gray-300 hover:bg-gray-200 disabled:bg-gray-100 disabled:cursor-not-allowed"
    >
      &lt;
    </button>

    <!-- First Page -->
    <button
      v-if="currentPage > 3 && !isMobile"
      type="button"
      @click="goToPage(1)"
      class="px-2 py-1 rounded border border-gray-300 bg-white hover:bg-gray-200 text-gray-800"
    >
      1
    </button>
    <button
      v-if="currentPage > 4 && !isMobile"
      type="button"
      @click="goToPage(Math.max(currentPage - 5, 1))"
      class="px-2 py-1 rounded border border-gray-300 bg-white hover:bg-gray-200 text-gray-800"
    >
      ...
    </button>

    <!-- Dynamic Page Numbers -->
    <button
      v-for="page in displayedPages"
      :key="page"
      @click="goToPage(page)"
      :class="[
        'px-2 py-1 rounded border text-sm',
        currentPage === page
          ? 'bg-emerald-500 text-white border-emerald-500'
          : 'bg-white text-gray-800 hover:bg-gray-200',
      ]"
      :disabled="currentPage === page || disabled"
    >
      {{ page }}
    </button>

    <!-- Last Page -->
    <button
      v-if="totalPages - currentPage > 3 && !isMobile"
      type="button"
      @click="goToPage(Math.min(currentPage + 5, totalPages))"
      class="px-2 py-1 rounded border border-gray-300 bg-white hover:bg-gray-200 text-gray-800"
    >
      ...
    </button>
    <button
      v-if="totalPages - currentPage >= 3 && !isMobile"
      type="button"
      @click="goToPage(totalPages)"
      class="px-2 py-1 rounded border border-gray-300 bg-white hover:bg-gray-200 text-gray-800"
    >
      {{ totalPages }}
    </button>

    <!-- Next Button -->
    <button
      @click="nextPage"
      type="button"
      :disabled="currentPage === totalPages || disabled"
      class="btn px-2 py-1 rounded border border-gray-300 hover:bg-gray-200 disabled:bg-gray-100 disabled:cursor-not-allowed"
    >
      &gt;
    </button>

    <!-- Jump to Page Input -->
    <div
      class="flex items-center space-x-1 sm:ml-2 w-full sm:w-auto justify-center"
    >
      <input
        v-model.number="jumpTo"
        type="number"
        :max="totalPages"
        :min="1"
        class="w-16 px-2 py-1 border rounded border-gray-300 text-center text-sm focus:outline-none focus:ring focus:ring-emerald-300"
        placeholder="Page"
            :class="isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'"
        
      />
      <button
        @click="goToPage(jumpTo)"
        :disabled="jumpTo < 1 || jumpTo > totalPages || disabled"
        class="px-2 py-1 rounded bg-emerald-500 text-white hover:bg-emerald-600 disabled:bg-gray-300 text-sm"
      >
        Go
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    disabled: { type: Boolean, default: false },
    totalItems: { required: true },
    pageSize: { required: true },
    currentPage: { required: true },
  },
  emits: ['update:currentPage'],
  data() {
    return {
      jumpTo: null,
      isMobile: window.innerWidth < 640, // Mobile detection
    };
  },
  computed: {
    ...mapGetters(['isDarkMode']),
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    displayedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      if (totalPages <= (this.isMobile ? 3 : 5)) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
      }

      const pages = [];
      if (currentPage > 1) pages.push(currentPage - 1);
      pages.push(currentPage);
      if (currentPage < totalPages) pages.push(currentPage + 1);

      return pages;
    },
  },
  methods: {
    goToPage(page) {
      if (page && page >= 1 && page <= this.totalPages) {
        this.$emit('update:currentPage', page);
        this.jumpTo = null; // Reset jump-to input
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.$emit('update:currentPage', this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('update:currentPage', this.currentPage + 1);
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth < 640;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
